/* tabs */
@media screen and (max-width: 992px) {
  .home-hero-sec {
    min-height: auto;
  }
  .h-450 {
    height: 450px;
  }
  header.sticky {
    height: 44px;
  }
  header {
    padding: 0 25px;
  }
  header nav .menu-toggler {
    display: block;
    height: 44px;
  }
  header .brand-logo a img {
    height: 64px;
  }
  header nav ul {
    position: fixed;
    z-index: 3;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    align-items: flex-start;
    transition: 0.25s ease;
  }
  header nav ul li {
    padding: 10px 0;
  }
  header nav ul li a {
    font-size: 26px;
    height: 40px;
  }
  .border-end,
  .border-start {
    border: none !important;
  }
  .sec-02,
  .sec-03 {
    padding: 40px 0 0px;
  }
  .sec-03 {
    margin-bottom: 80px;
  }
  .th-bg-secondary-light::before {
    top: 430px;
    left: 0;
    width: 100%;
    transform: translateY(0);
    height: calc(100% - 418px);
  }
  .img-fluid {
    width: 100%;
  }
  .banner-fluid {
    width: 100%;
  }
  .technology-fluid {
    width: 55%;
  }
  /* .icon {
    width: 100%;
    height: 50px;
  } */
  footer .top-footer .footer-logo {
    height: 107px;
    width: 135px;
    margin-right: 70px;
  }
  footer .company-logo {
    height: 107px;
    width: 135px;
    margin-right: 70px;
  }
  .mob-width {
    width: 100%;
  }
  footer .top-footer .footer-signup-form input[type='number'] {
    width: 100% !important;
  }
  footer .top-footer .footer-signup-form button[type='submit'] {
    width: 100% !important;
    margin-left: 0;
  }
  footer .top-footer .footer-signup-form input[type='email'] {
    width: 100% !important;
  }
  .menu-2 {
    /* overflow-y: auto; */
    /* overflow-x: hidden; */
    padding-top: 210px;
    /* padding-bottom: 10px; */
    align-items: flex-start;
  }
  .home-hero-sec .h-text-box {
    position: relative;
  }
  .home-hero-sec .h-text-box .main-text h1 {
    font-size: 36px;
  }
  .home-hero-sec .h-text-box {
    width: 100%;
    flex-direction: column;
    border-radius: 0 0 0 0;
  }
  .home-hero-sec .h-text-box .left-vbar {
    padding: 5px 15px;
  }
  .home-hero-sec .h-text-box .left-vbar span {
    position: relative;
    top: 0;
    left: 0;
    transform: rotate(0deg);
  }
  .home-hero-sec .h-text-box .main-text {
    padding: 15px;
  }
  .sec-02 .card .card-body .body-title {
    font-size: 23px;
    line-height: 34px;
    text-align: left;
  }
  .border-md-start {
    border-left: 1px solid #dee2e6 !important;
  }
  h1,
  .fs-44 {
    font-size: 36px !important;
  }
  .sec-04 .img-wrapper {
    height: 430px;
  }
  .container {
    max-width: 740px;
  }
  .main-footer .foo-list-1 ul li {
    padding: 5px 0;
  }
  .img-wrapper h1 {
    font-size: 34px !important;
    max-width: 400px;
  }
  .img-wrapper h1 br {
    display: none;
  }
  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    max-height: 53px;
  }
  .link_btn {
    width: 100%;
  }
  .main-footer {
    padding: 15px 0 60px;
  }
  .home-hero-sec .search-bar,
  .page-hero-sec .search-bar {
    right: 18px;
  }
  .img-wrapper .link_btn {
    width: 250px;
  }
  .menu-2 .container {
    padding-left: 25px !important ;
  }
  .menu-2 .menu2-item {
    font-size: 22px !important;
    margin-bottom: 15px;
    display: inline-block;
  }
  .home-hero-sec img {
    border-radius: 15px 15px 0 0;
  }
  .administration-sec h1 {
    font-size: 34px !important;
  }
  .administration-sec p {
    max-width: 450px;
    margin: 0 auto 20px;
  }
}

/* mobiles */
@media screen and (max-width: 576px) {
  footer .top-footer {
    flex-direction: column;
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  h1,
  .fs-44 {
    font-size: 26px !important;
  }
  .sec-04 .hr-line {
    width: 250px;
  }

  header {
    padding: 0 20px;
  }
  header .brand-text svg {
    width: 100px;
    height: 15px;
  }
  header .brand-text svg path {
    fill: var(--primary);
  }
  .h-450 {
    height: 250px;
  }
  .home-hero-sec {
    background-image: none;
    min-height: 0;
  }

  .home-hero-sec .search-bar,
  .page-hero-sec .search-bar {
    top: 15px;
    right: 15px;
  }
  .home-hero-sec .h-text-box {
    position: relative;
  }
  .home-hero-sec .h-text-box .main-text h1 {
    font-size: 26px;
  }
  .home-hero-sec .h-text-box {
    width: 100%;
    flex-direction: column;
    border-radius: 0 0 0 0;
  }
  .home-hero-sec .h-text-box .left-vbar {
    padding: 4px 15px;
  }
  .home-hero-sec .h-text-box .left-vbar span {
    position: relative;
    top: 0;
    left: 0;
    transform: rotate(0deg);
  }
  .home-hero-sec .h-text-box .main-text {
    padding: 15px 0px;
  }
  .sec-02 .card .card-body .body-title {
    font-size: 24px;
    line-height: 26px;
  }

  footer .top-footer {
    padding: 25px 20px;
  }
  .mob-width {
    width: 100%;
  }
  footer .top-footer .footer-signup-form input[type='number'] {
    width: 100% !important;
  }
  footer .top-footer .footer-signup-form button[type='submit'] {
    width: 100%;
    margin-left: 0;
  }
  .main-footer {
    padding: 0px 0 30px;
  }
  .th-bg-secondary-light::before {
    top: 200px;
    height: calc(100% - 185px);
  }
  .sec-04 .img-wrapper h1 {
    font-size: 36px;
  }
  .menu-2 {
    padding-top: 135px;
  }
  .menu-2::before {
    display: none;
  }
  .sec-02,
  .sec-03 {
    padding: 20px 0 0px;
  }
  .sec-03 {
    margin-bottom: 50px;
  }
  .sec-04 .img-wrapper {
    height: 380px;
    padding: 50px 0;
  }
  header.sticky {
    height: 44px;
  }
  header .brand-logo {
    top: 3px;
  }
  header {
    height: 56px;
  }
  header .brand-logo a img {
    width: 72px;
    height: 51px;
  }
  header nav .menu-toggler {
    height: 44px;
  }
  .wrapper {
    padding-top: 56px;
  }
  footer .bg-r-img img {
    right: -50%;
    top: 50%;
    bottom: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 511px;
  }
  .border-md-start {
    border-left: none !important;
  }
  .main-footer .foo-list-1 ul li a {
    font-weight: 600;
    font-size: 13px !important;
  }
  hr {
    margin: 15px 0 25px;
  }
  .img-wrapper h1 {
    font-size: 31px !important;
    max-width: 280px;
  }
  .img-wrapper h1 br {
    display: none;
  }
  footer .top-footer .footer-logo {
    display: none;
  }
  footer .company-logo {
    display: none;
  }
  .page-hero-sec {
    padding-top: 60px;
  }
  .page-hero-sec h1 {
    font-size: 31px !important;
  }
  .menu-2-brand-logo {
    top: 20px;
    left: 20px;
  }
  .menu-2-close {
    top: 35px;
    right: -15px;
  }
  .menu-2 .menu2-item {
    margin-bottom: 15px;
    display: inline-block;
  }
  .menu-2 .social-links {
    padding-top: 30px !important;
    padding-bottom: 50px !important;
  }
  .president-sec .president-img {
    height: 345px;
  }
}

@media screen and (min-width: 900px) {
  header nav .menu-toggler.open {
    top: 0;
  }
  header nav .menu-toggler.open .menu_btn_text {
    color: var(--danger);
    position: relative;
    font-weight: 600;
  }
  header nav .menu-toggler.open::before {
    display: none;
  }
  .m2_navs {
    height: auto;
    padding-top: 50px;
  }
}
