:root {
  --white: #fff;
  --white-rgb: 255, 255, 255;
  --black: #000;
  --black-rgb: 0, 0, 0;
  --primary: #0a2458;
  --primary-rgb: 10, 36, 88;
  --primary-light: #0064bc;
  --primary-light-rgb: 0, 100, 188;
  --secondary: #aa604f;
  --secondary-rgb: 170, 96, 79;
  --danger: #9c1919;
  --danger-rgb: 156, 25, 25;
}
@font-face {
  font-family: MercurySSm-Book-Pro_Web, serif;
  src: url(../fonts/MercurySSm-Book-Pro_Web.woff2);
}
@font-face {
  font-family: MercuryTextG2-Semibold-Pro_Web;
  src: url(../fonts/MercuryTextG2-Semibold-Pro_Web.woff2);
}
@font-face {
  font-family: Decimal-Medium_Web, sans-serif;
  src: url(../fonts/Decimal-Medium_Web.woff2);
}
@font-face {
  font-family: Decimal-Book, sans-serif;
  src: url(../fonts/Decimal-Book.woff2);
}
body {
  margin: 0;
  padding: 0;
  font-family: Decimal-Medium_Web, sans-serif;
}
input,
select,
textarea {
  font-family: Decimal-Book, sans-serif;
}
label {
  font-family: Decimal-Medium_Web, sans-serif;
  font-size: 10px;
  letter-spacing: 0.04em;
  line-height: 125%;
  text-transform: uppercase;
  font-weight: 400;
}
a {
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: 0.35s ease;
}
a:hover {
  color: var(--danger);
  border-bottom-color: var(--danger);
}
p {
  font-family: MercurySSm-Book-Pro_Web, serif;
  font-size: 17px;
}
button {
  cursor: pointer;
  outline: none;
  border: none;
}
h1 {
  font-size: 54px;
  font-variant: small-caps;
}
h2 {
  font-variant: small-caps;
}
hr {
  height: 1px;
  background-color: rgba(var(--primary-rgb), 0.4);
  margin: 15px 0;
}
.head-font {
  font-family: MercuryTextG2-Semibold-Pro_Web;
}
.sm-caps {
  font-variant: small-caps;
}
.head-font2 {
  font-family: MercurySSm-Book-Pro_Web, serif;
}
.para-font {
  font-family: Decimal-Medium_Web, sans-serif;
}
ul.simple-list {
  font-family: MercurySSm-Book-Pro_Web, serif;
  padding-left: 20px;
}
ul.simple-list li {
  padding-bottom: 15px;
  font-size: 17px;
}
.sm-caps-none {
  font-variant: normal;
}
.tc-primary {
  color: var(--primary);
}
.tc-primary-light {
  color: var(--primary-light);
}
.tc-primary-light-bordered {
  color: var(--primary-light);
  border-bottom: 1px solid rgba(var(--primary-light-rgb), 0.2);
}
.tc-secondary {
  color: var(--secondary);
}
.bg-light {
  background-color: #f0f0f0;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-28 {
  font-size: 28px !important;
}
.fs-44 {
  font-size: 44px !important;
}
.brr-15 {
  border-radius: 15px;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.bg-left::before {
  left: 0;
}
.bg-right::before {
  right: 0;
}
.th-bg-secondary-light::before {
  content: '';
  position: absolute;
  top: 50%;
  width: 70%;
  transform: translateY(-50%);
  height: calc(100% - 80px);
  z-index: -1;
  background-color: #f7f7f7;
}
.h-450 {
  height: 450px;
}
.img-fluid {
  object-fit: cover;
}
.banner-fluid {
  object-fit: cover;
}
.technology-fluid {
  object-fit: contain;
}
::selection {
  color: var(--white);
  background-color: var(--danger);
}
.border-left-secondary-2 {
  border-left: 2px solid var(--secondary);
}
.border-left-secondary-4 {
  border-left: 4px solid var(--secondary);
}
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  padding: 0 40px;
  height: 60px;
  transition: 0.25s ease;
  z-index: 999;
}
header.sticky {
  height: 55px;
  box-shadow: 0 10px 15px -5px rgba(var(--primary-rgb), 0.15);
}
header .brand-text a {
  display: inline-block;
  font-size: 18px;
  letter-spacing: 2px;
  transition: 0.35s ease;
}
header .brand-text a:hover {
  color: var(--danger);
}
header .brand-logo {
  position: fixed;
  z-index: 2;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
}
header .brand-logo a img {
  width: 100px;
  height: 60px;
}
header nav,
header nav ul {
  display: flex;
  margin: 0;
  align-items: center;
}
header nav ul {
  padding-right: 30px;
}
header nav ul li {
  display: flex;
  padding: 0 15px;
}
header nav ul li a {
  display: inline-block;
  height: 22px;
  font-size: 14px;
  padding: 0;
  color: var(--primary);
  border-bottom: 1px solid rgba(var(--primary-rgb), 0.2);
  transition: 0.35s ease;
  font-weight: 500;
  letter-spacing: 0.2px;
}
header nav ul li a:hover {
  color: var(--danger);
  border-bottom-color: var(--danger);
}
header nav .menu-toggler {
  color: var(--primary);
  background-color: var(--white);
  font-size: 11px;
  font-weight: 500;
  transition: 0.25s ease;
  z-index: 9;
}
header nav .menu-toggler.open {
  position: relative;
  top: -9px;
}
header nav .menu-toggler.open::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 67px;
  background-color: var(--primary);
}
header nav .menu-toggler.open .menu_btn_text {
  color: #fff;
  position: relative;
  font-weight: 600;
}
.bar2.active {
  position: relative;
  display: block;
}
.bar2.active::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--secondary);
  transform: rotate(45deg);
}
.bar2.active::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--secondary);
  transform: rotate(-45deg);
}
header nav .menu-toggler {
  width: 50px;
  height: 50px;
}
header nav .menu-toggler:hover {
  color: var(--danger);
}
header nav .menu-toggler .menu_btn_text {
  display: block;
  margin: 3px 0;
  transition: 0.25s ease;
}
header nav .menu-toggler:hover .menu_btn_text {
  margin: 4px 0;
}
header nav .menu-toggler .menu-bars {
  display: block;
  width: 100%;
  height: 4px;
  border-top: 1px solid var(--primary);
  border-bottom: 1px solid var(--primary);
  transition: 0.25s ease;
}
header nav .menu-toggler:hover .menu-bars {
  transform: scale(1.15);
  border-color: var(--danger);
}
.menu-2 {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  z-index: 5;
  transition: 0.15s ease;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-2::before {
  content: '';
  position: absolute;
  z-index: -1;
  right: -170px;
  bottom: -50px;
  width: 1064px;
  height: 550px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.menu-2-brand-logo {
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 2;
  width: 86px;
  height: 61px;
}
.menu-2-close {
  position: absolute;
  top: 55px;
  right: 10px;
  z-index: 2;
  width: 86px;
  height: 61px;
}
.menu-2 .menu2-item {
  border-bottom: 1px solid rgba(10, 36, 88, 0.25);
}
.menu-2 .menu2-item:hover {
  border-bottom-color: var(--danger);
}
.menu-2 .right-menus-items-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.menu-2 .social-links a {
  border: none !important;
  margin-right: 10px;
}
.m2_navs {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 200px);
}
.icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.wrapper {
  padding-top: 60px;
  width: 100%;
}
.home-hero-sec {
  background-image: url(../images/banner/custom_software_development.jpg);
  background-size: cover;
  background-position: center;
  min-height: 90vh;
  margin: 0 15px;
  border-radius: 15px;
  position: relative;
}
.home-hero-sec .search-bar,
.page-hero-sec .search-bar {
  position: absolute;
  top: 20px;
  right: 40px;
}
.home-hero-sec .search-bar form,
.page-hero-sec .search-bar form {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0e5ef;
  border-radius: 50px;
  padding: 0 5px;
  transition: 0.2s ease;
  overflow: hidden;
}
.home-hero-sec .search-bar form button,
.page-hero-sec .search-bar form button {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: var(--primary);
}
.home-hero-sec .search-bar form input,
.page-hero-sec .search-bar form input {
  flex: 1;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding-left: 10px;
  letter-spacing: 1px;
}
.home-hero-sec .search-bar form button[type='submit'],
.home-hero-sec .search-bar form input,
.page-hero-sec .search-bar form button[type='submit'],
.page-hero-sec .search-bar form input {
  display: none;
}
.home-hero-sec .h-text-box {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 540px;
  display: flex;
  background-color: var(--white);
  border-radius: 15px 0 0 0;
  overflow: hidden;
}
.home-hero-sec .h-text-box .left-vbar {
  background-color: #edbe87;
  padding: 17px 20px;
}
.home-hero-sec .h-text-box .left-vbar span {
  position: absolute;
  top: 20px;
  left: 30px;
  transform: rotate(90deg);
  transform-origin: 0 0;
  font-size: 13px;
  font-weight: 500;
  color: var(--primary);
}
.home-hero-sec .h-text-box .main-text {
  flex: 1;
  padding: 45px;
}
.home-hero-sec .h-text-box .main-text h1 {
  font-size: 44px;
}
.home-hero-sec .h-text-box .main-text ul,
.sec-03 ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.home-hero-sec .h-text-box .main-text ul li,
.sec-03 ul li {
  padding: 10px 0;
}
.home-hero-sec .h-text-box .main-text ul li a,
.sec-03 ul li a {
  padding: 5px 15px;
  color: var(--primary);
  font-size: 14px;
  position: relative;
  display: inline-block;
  font-weight: 500;
  transition: 0.35s ease;
}
.home-hero-sec .h-text-box .main-text ul li a:hover,
.sec-03 ul li a:hover {
  color: var(--secondary);
  border-bottom-color: transparent;
}
.home-hero-sec .h-text-box .main-text ul li a::before,
.sec-03 ul li a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: var(--secondary);
  transition: 0.35s ease;
}
.sec-02,
.sec-03 {
  padding: 80px 0;
  position: relative;
  z-index: 1;
}
.dot {
  width: 3px;
  height: 3px;
  display: block;
  border-radius: 50%;
  background-color: var(--primary-light);
  margin: 5px;
}
.sec-02 .card img {
  border-radius: 15px;
}
.sec-02 .card img {
  border-radius: 15px;
}
.sec-02 .card .card-body .body-title {
  font-size: 34px;
  line-height: 44px;
  font-weight: 500;
  margin: 15px 0;
  transition: 0.35s ease;
}
.sec-02 .card .card-body .body-title:hover,
.sec-02 .card .card-body a:hover {
  color: var(--danger);
  border-bottom: 1px solid var(--danger);
}
.sec-02 .card .card-body p {
  font-size: 12px;
  letter-spacing: 1px;
}
.sec-02 .card .card-body a {
  transition: 0.35s ease;
}
.sec-04 {
  padding: 40px 0;
}
.sec-04 .img-wrapper {
  width: 100%;
  height: 540px;
  position: relative;
  z-index: 1;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sec-04 .img-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: 0.6;
  border-radius: 15px;
}
.sec-04 .img-wrapper .tag {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  padding: 15px 30px;
  border-radius: 0 15px 0 15px;
  background-color: #edbe87;
  color: var(--primary);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}
.sec-04 .img-wrapper img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.sec-04 .img-wrapper h1 {
  z-index: 2;
  font-size: 54px;
  color: var(--white);
  text-align: center;
}
.sec-04 .hr-line {
  width: 400px;
  z-index: 2;
  background-color: var(--white);
  opacity: 0.1;
}
.link_btn {
  width: 250px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0064bc;
  color: var(--white);
  z-index: 2;
  font-size: 14px;
  font-weight: 500;
}
.link_btn:hover {
  border-bottom-color: transparent;
  background-color: #184089;
  color: var(--white);
}
.button {
  width: 190px;
  padding: 16.5px 25px;
  background: var(--primary);
  border-radius: 50px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 16px;
  transition: 0.35s ease;
  margin: 0 auto;
}
footer {
  position: relative;
  overflow: hidden;
  padding-top: 50px;
}
footer .bg-r-img {
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  width: 685px;
  height: 685px;
  overflow: hidden;
  display: none;
}
footer .bg-r-img img {
  position: relative;
  right: -7.86%;
  bottom: -14.83%;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
footer .top-footer {
  border: 3px solid var(--primary);
  border-radius: 16px;
  padding: 15px 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
footer .top-footer .footer-logo {
  width: 135px;
  height: 107px;
}
footer .company-logo {
  width: 330px;
  height: 110px;
}
footer .top-footer .footer-signup-form label {
  letter-spacing: 1px;
}
footer .top-footer .footer-signup-form input {
  border-radius: 8px;
  background-color: #e0e5ef;
  color: var(--primary);
  border: 1px solid #e0e5ef;
  transition: 0.35s ease;
  padding: 15px;
  height: 53px;
}
footer .top-footer .footer-signup-form input[type='email'] {
  width: 360px !important;
}
footer .top-footer .footer-signup-form input[type='number'] {
  width: 140px !important;
}
footer .top-footer .footer-signup-form button[type='submit'] {
  width: 190px;
  padding: 16.5px 25px;
  background: var(--primary);
  border-radius: 50px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 14px;
  margin-top: 22px;
  margin-left: 10px;
  transition: 0.35s ease;
}
footer .top-footer .footer-signup-form button[type='submit']:hover {
  background: #184089;
}
.main-footer {
  padding: 40px 0 80px;
}
.main-footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.main-footer .foo-list-1 ul li {
  padding: 8px 0;
}
.main-footer .foo-list-1 ul li a {
  padding: 4px 0;
  font-weight: 500;
  letter-spacing: 1px;
  border-bottom: 1px solid rgba(var(--primary-rgb), 0.25);
}
.main-footer .foo-list-2 .social-links a {
  display: inline-block;
  margin-right: 20px;
}
.main-footer .foo-list-2 .social-links a:hover,
.main-footer .foo-brand-link:hover {
  border-bottom-color: transparent;
}
.main-footer .foo-brand-link {
  font-size: 24px;
  user-select: none;
  letter-spacing: 1px;
}
.main-footer .foo-brand-link svg {
  width: 114px;
  height: 17px;
  fill: #876450;
  transition: 0.2s;
}
.page-hero-sec {
  background-color: var(--white);
  position: relative;
  padding-top: 80px;
}
.bg-blue {
  background-color: var(--primary);
  padding-bottom: 100px;
  margin: 0 15px;
  border-radius: 15px;
}
.page-hero-sec .cards {
  padding: 15px 0;
  transition: 0.25s ease;
}
.page-hero-sec .cards:hover {
  padding-top: 5px;
}
.page-hero-sec .cards a img {
  border-radius: 10px;
}
.page-hero-sec .cards a h6 {
  letter-spacing: 1px;
  transition: 0.35s ease;
}
.page-hero-sec .cards a:hover h6 {
  color: var(--danger);
}
.hr-double {
  width: 145px;
  height: 3px !important;
  background: transparent;
  border-top: 1px solid var(--primary);
  border-bottom: 1px solid var(--primary);
  opacity: 1;
  margin: 15px auto;
}
.first_letter_caps::first-letter {
  float: left;
  padding-right: 25px;
  text-transform: uppercase;
  font-size: 80px;
  font-weight: 600;
  line-height: 80px;
  color: var(--secondary);
}
.accordion-button {
  color: var(--primary);
  background-color: var(--white);
  box-shadow: none !important;
  padding: 15px 25px 15px 0;
  font-size: 18px;
  font-family: 'Lora', serif;
}
.accordion-button:not(.collapsed) {
  color: var(--primary);
  background-color: var(--white);
  box-shadow: none;
}
.accordion-item {
  background-color: var(--white);
  border: none;
  border-bottom: 1px solid rgba(var(--primary-rgb), 0.125);
}
.accordion-body {
  padding: 0 0 15px 0;
  font-size: 14px;
  color: var(--primary);
  font-family: 'Lora', serif;
}
.page-item {
  margin: 0 20px;
}
.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-link {
  display: block;
  color: var(--primary);
  background-color: #fff;
  border: none;
  border-bottom: 1px solid rgba(var(--primary-rgb), 0.2);
  transition: 0.35s ease;
  border-radius: none;
  padding: 5px;
  font-weight: 600;
}
.page-link.active {
  color: var(--secondary);
  border-bottom: 1px solid transparent;
}
.page-link:hover {
  color: var(--danger);
  border-bottom-color: var(--danger);
  background-color: transparent;
}
.contact-form label {
  margin-bottom: 3px;
  color: var(--primary);
}
.contact-form input,
.contact-form select,
.contact-form textarea {
  width: 100%;
  padding: 16.5px 14px 16.5px 13px;
  border-radius: 8px;
  background-color: #e0e5ef;
  color: var(--primary);
  border: 1px solid #e0e5ef;
  transition: 0.2s ease;
  outline: none;
}
.contact-form input::placeholder,
.contact-form select::placeholder,
.contact-form textarea::placeholder {
  color: var(--primary);
  opacity: 0.6;
}
.contact-form input[type='checkbox'] {
  max-width: 15px;
  margin-right: 10px;
}
.president-sec .president-img {
  height: 625px;
}
a.menu-toggler {
  color: var(--primary) !important;
  font-size: 14px;
  font-family: Decimal-Medium_Web, sans-serif;
}
a.menu-toggler:hover {
  cursor: pointer !important;
}
.activePage {
  color: var(--danger) !important;
  border-bottom-color: var(--danger) !important;
}
